import { Component, Input, OnInit } from '@angular/core';
import { EmployerFilterService } from "../../../../services/employer-filter.service";
import { Observable } from "rxjs";
import { Employer, EmployerApiModel } from "../../../../../api-new";

@Component({
  selector: 'app-employer-filter',
  templateUrl: './employer-filter.component.html',
  styleUrls: ['./employer-filter.component.scss']
})
export class EmployerFilterComponent implements OnInit {

  selectedEmployerId: number | undefined = undefined;
  employerFilterSub: any;
  employers: Array<EmployerApiModel> | null;

  disable$: Observable<boolean>;

  @Input() canDeselect: boolean = false;

  constructor(private employerFilterService: EmployerFilterService) {
    this.disable$ = this.employerFilterService.disable$;
  }

  ngOnInit() {
    this.employerFilterService.loadedEmployers$.subscribe(employers => {
      this.employers = employers
    })
    this.employerFilterSub = this.employerFilterService.selectedEmployer$.subscribe(employee => {
      this.selectedEmployerId = employee?.id;
    })
    this.employerFilterService.reload();
  }

  ngOnDestroy() {
    this.employerFilterSub.unsubscribe()
  }

  handleSelection(employerId: number) {
    this.employerFilterService.changeEmployee(employerId)
  }

  selectorOpen() {
    this.employerFilterService.reload()
  }

  clearSelection() {
    this.selectedEmployerId = undefined;
    this.employerFilterService.changeEmployee(null);
  }

  getSelectedEmployerName() {
    return this.selectedEmployerId && this.employers ? this.employers.filter(emp => emp.id == this.selectedEmployerId)[0].name : '';
  }

  get hasItems() {
    return this.employers
  }
}
