// @ts-nocheck
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AdditionalEmployeeAssesibleNewApiModel } from '../model/models';
import { AssignableWorkItemApiModel } from '../model/models';
import { CreateEmployeeApiModel } from '../model/models';
import { EmployeeActiveState } from '../model/models';
import { EmployeeAssessibilitiesApiModel } from '../model/models';
import { EmployeeAssessmentApiModel } from '../model/models';
import { EmployeeContractApiModel } from '../model/models';
import { EmployeeContractCreateApiModel } from '../model/models';
import { EmployeeContractUpdateApiModel } from '../model/models';
import { EmployeeDataApiModel } from '../model/models';
import { EmployeeDetailApiModel } from '../model/models';
import { EmployeeDetailApiModelNew } from '../model/models';
import { EmployeeDetailsUpdateApiModel } from '../model/models';
import { EmployeeExpiringAssessiblesApiModel } from '../model/models';
import { EmployeeExpiringAssessiblesListPageApiModel } from '../model/models';
import { EmployeeGridListApiModel } from '../model/models';
import { HttpError } from '../model/models';
import { ImportAssessedRecordsApiModel } from '../model/models';
import { PageApiModelOfEmployeeListApiModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

    protected basePath = 'https://localhost:61175';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param newEmployeeData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModel>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModel>>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModel>>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newEmployeeData === null || newEmployeeData === undefined) {
            throw new Error('Required parameter newEmployeeData was null or undefined when calling employeeCreateEmployee.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EmployeeDetailApiModel>(`${this.configuration.basePath}/api/Employee`,
            newEmployeeData,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param additionalEmployeeAssesibleNewApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateEmployeeAssessibility(additionalEmployeeAssesibleNewApiModel: AdditionalEmployeeAssesibleNewApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public employeeCreateEmployeeAssessibility(additionalEmployeeAssesibleNewApiModel: AdditionalEmployeeAssesibleNewApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public employeeCreateEmployeeAssessibility(additionalEmployeeAssesibleNewApiModel: AdditionalEmployeeAssesibleNewApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public employeeCreateEmployeeAssessibility(additionalEmployeeAssesibleNewApiModel: AdditionalEmployeeAssesibleNewApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (additionalEmployeeAssesibleNewApiModel === null || additionalEmployeeAssesibleNewApiModel === undefined) {
            throw new Error('Required parameter additionalEmployeeAssesibleNewApiModel was null or undefined when calling employeeCreateEmployeeAssessibility.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/Employee/assessibilities`,
            additionalEmployeeAssesibleNewApiModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param employeeContractCreateApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeContractApiModel>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeContractApiModel>>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeContractApiModel>>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeCreateEmployeeContract.');
        }
        if (employeeContractCreateApiModel === null || employeeContractCreateApiModel === undefined) {
            throw new Error('Required parameter employeeContractCreateApiModel was null or undefined when calling employeeCreateEmployeeContract.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EmployeeContractApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/contract`,
            employeeContractCreateApiModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param assessibleItemId 
     * @param expireAt 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeDeleteEmployeeAssessibility(employeeId: number, assessibleItemId: number, expireAt?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public employeeDeleteEmployeeAssessibility(employeeId: number, assessibleItemId: number, expireAt?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public employeeDeleteEmployeeAssessibility(employeeId: number, assessibleItemId: number, expireAt?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public employeeDeleteEmployeeAssessibility(employeeId: number, assessibleItemId: number, expireAt?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeDeleteEmployeeAssessibility.');
        }
        if (assessibleItemId === null || assessibleItemId === undefined) {
            throw new Error('Required parameter assessibleItemId was null or undefined when calling employeeDeleteEmployeeAssessibility.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (expireAt !== undefined && expireAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expireAt, 'expireAt');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.delete(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(employeeId))}/assessibilities/${encodeURIComponent(String(assessibleItemId))}`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param page 
     * @param onPage 
     * @param sortBy 
     * @param sortDir 
     * @param secondarySortBy 
     * @param secondarySortDir 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public employeeGet(page?: number, onPage?: number, sortBy?: string, sortDir?: 'ASC' | 'DESC', secondarySortBy?: string, secondarySortDir?: 'ASC' | 'DESC', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<PageApiModelOfEmployeeListApiModel>;
    public employeeGet(page?: number, onPage?: number, sortBy?: string, sortDir?: 'ASC' | 'DESC', secondarySortBy?: string, secondarySortDir?: 'ASC' | 'DESC', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<PageApiModelOfEmployeeListApiModel>>;
    public employeeGet(page?: number, onPage?: number, sortBy?: string, sortDir?: 'ASC' | 'DESC', secondarySortBy?: string, secondarySortDir?: 'ASC' | 'DESC', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<PageApiModelOfEmployeeListApiModel>>;
    public employeeGet(page?: number, onPage?: number, sortBy?: string, sortDir?: 'ASC' | 'DESC', secondarySortBy?: string, secondarySortDir?: 'ASC' | 'DESC', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (onPage !== undefined && onPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>onPage, 'onPage');
        }
        if (sortBy !== undefined && sortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortBy, 'sortBy');
        }
        if (sortDir !== undefined && sortDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortDir, 'sortDir');
        }
        if (secondarySortBy !== undefined && secondarySortBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>secondarySortBy, 'secondarySortBy');
        }
        if (secondarySortDir !== undefined && secondarySortDir !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>secondarySortDir, 'secondarySortDir');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PageApiModelOfEmployeeListApiModel>(`${this.configuration.basePath}/api/Employee`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGet2(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModelNew>;
    public employeeGet2(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModelNew>>;
    public employeeGet2(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModelNew>>;
    public employeeGet2(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGet2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeDetailApiModelNew>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public employeeGetAssessibilities(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeAssessibilitiesApiModel>;
    public employeeGetAssessibilities(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeAssessibilitiesApiModel>>;
    public employeeGetAssessibilities(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeAssessibilitiesApiModel>>;
    public employeeGetAssessibilities(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGetAssessibilities.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeAssessibilitiesApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/assessibilities`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetAssignableWorkItems(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<AssignableWorkItemApiModel>>;
    public employeeGetAssignableWorkItems(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<AssignableWorkItemApiModel>>>;
    public employeeGetAssignableWorkItems(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<AssignableWorkItemApiModel>>>;
    public employeeGetAssignableWorkItems(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGetAssignableWorkItems.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AssignableWorkItemApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/assignableWorkItems`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetEligibles(employeeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeExpiringAssessiblesApiModel>;
    public employeeGetEligibles(employeeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeExpiringAssessiblesApiModel>>;
    public employeeGetEligibles(employeeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeExpiringAssessiblesApiModel>>;
    public employeeGetEligibles(employeeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeGetEligibles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeExpiringAssessiblesApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(employeeId))}/eligibilities`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetEmployeeAssessments(id: number, status?: 'DRAFT' | 'ORDER' | 'FINISHED' | 'CONFIRMED', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeAssessmentApiModel>>;
    public employeeGetEmployeeAssessments(id: number, status?: 'DRAFT' | 'ORDER' | 'FINISHED' | 'CONFIRMED', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeAssessmentApiModel>>>;
    public employeeGetEmployeeAssessments(id: number, status?: 'DRAFT' | 'ORDER' | 'FINISHED' | 'CONFIRMED', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeAssessmentApiModel>>>;
    public employeeGetEmployeeAssessments(id: number, status?: 'DRAFT' | 'ORDER' | 'FINISHED' | 'CONFIRMED', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGetEmployeeAssessments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EmployeeAssessmentApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/assessments`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetEmployeesCount(employerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDataApiModel>;
    public employeeGetEmployeesCount(employerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDataApiModel>>;
    public employeeGetEmployeesCount(employerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDataApiModel>>;
    public employeeGetEmployeesCount(employerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employerId === null || employerId === undefined) {
            throw new Error('Required parameter employerId was null or undefined when calling employeeGetEmployeesCount.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeDataApiModel>(`${this.configuration.basePath}/api/Employee/count/${encodeURIComponent(String(employerId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param page 
     * @param onPage 
     * @param showExpired 
     * @param showPlaned 
     * @param limitExpiredDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeExpiringAssessiblesListPageApiModel>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeExpiringAssessiblesListPageApiModel>>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeExpiringAssessiblesListPageApiModel>>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (onPage !== undefined && onPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>onPage, 'onPage');
        }
        if (showExpired !== undefined && showExpired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showExpired, 'showExpired');
        }
        if (showPlaned !== undefined && showPlaned !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showPlaned, 'showPlaned');
        }
        if (limitExpiredDate !== undefined && limitExpiredDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limitExpiredDate, 'limitExpiredDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeExpiringAssessiblesListPageApiModel>(`${this.configuration.basePath}/api/Employee/expiringEligibilitiesNew`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param page 
     * @param onPage 
     * @param textSearch 
     * @param employeeActiveStates 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeGridListApiModel>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeGridListApiModel>>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeGridListApiModel>>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, employeeActiveStates?: Array<EmployeeActiveState>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (onPage !== undefined && onPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>onPage, 'onPage');
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>textSearch, 'textSearch');
        }
        if (employeeActiveStates) {
            employeeActiveStates.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'employeeActiveStates');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EmployeeGridListApiModel>>(`${this.configuration.basePath}/api/Employee/grid-employees`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Employer user can import employee from excel file. First row must be header.Excel file must contain columns (13) in this order Name, Surname, Date of birth, identification number (rodne cislo),employee personal indentificator, street, street number, street number 2, city, zip, state, email, phone numberIf employee personal indentificator is used imported person is skiped. Returns list of created employee.
     * @param employerId 
     * @param excelContent 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeImportEmployerEmployee(employerId?: number, excelContent?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeDetailApiModel>>;
    public employeeImportEmployerEmployee(employerId?: number, excelContent?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeDetailApiModel>>>;
    public employeeImportEmployerEmployee(employerId?: number, excelContent?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeDetailApiModel>>>;
    public employeeImportEmployerEmployee(employerId?: number, excelContent?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (excelContent !== undefined) {
            formParams = formParams.append('excelContent', <any>excelContent) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EmployeeDetailApiModel>>(`${this.configuration.basePath}/api/Employee/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param importExistingAssessedRecordsApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (importExistingAssessedRecordsApiModel === null || importExistingAssessedRecordsApiModel === undefined) {
            throw new Error('Required parameter importExistingAssessedRecordsApiModel was null or undefined when calling employeeImportExistingAssessedRecords.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/Employee/importExistingAssessedRecords`,
            importExistingAssessedRecordsApiModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeContractApiModel>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeContractApiModel>>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeContractApiModel>>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeLoadEmployeeContracts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EmployeeContractApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(employeeId))}/contract`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param employeeData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public employeeUpdateEmployee(id: number, employeeData: CreateEmployeeApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModel>;
    public employeeUpdateEmployee(id: number, employeeData: CreateEmployeeApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModel>>;
    public employeeUpdateEmployee(id: number, employeeData: CreateEmployeeApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModel>>;
    public employeeUpdateEmployee(id: number, employeeData: CreateEmployeeApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeUpdateEmployee.');
        }
        if (employeeData === null || employeeData === undefined) {
            throw new Error('Required parameter employeeData was null or undefined when calling employeeUpdateEmployee.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<EmployeeDetailApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}`,
            employeeData,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param newContract 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeContractApiModel>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeContractApiModel>>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeContractApiModel>>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newContract === null || newContract === undefined) {
            throw new Error('Required parameter newContract was null or undefined when calling employeeUpdateEmployeeContract.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<EmployeeContractApiModel>(`${this.configuration.basePath}/api/Employee/contract`,
            newContract,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employee 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModelNew>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModelNew>>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModelNew>>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employee === null || employee === undefined) {
            throw new Error('Required parameter employee was null or undefined when calling employeeUpdatePersonalData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<EmployeeDetailApiModelNew>(`${this.configuration.basePath}/api/Employee/personaldata`,
            employee,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
