/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeContractApiModel } from './employeeContractApiModel';
import { AddressApiModel } from './addressApiModel';


export interface EmployeeDetailApiModel { 
    id: number;
    name: string;
    surname: string;
    dateOfBirth: string;
    address: string;
    email: string;
    phoneNumber: string;
    birthNumber: string;
    personalNumber: string;
    active: boolean;
    contracts: Array<EmployeeContractApiModel>;
    addressDto: AddressApiModel;
}

