import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from "@nebular/theme";
import { Provider, AssessmentService, AssessmentTypeApiModel, ProviderService } from "../../../../../api-new";

export interface CustomProvider {
  id: number | undefined;
  name:string;
}

export interface ProviderResponseDialog {
  provider: CustomProvider;
  assessment? : AssessmentTypeApiModel;
}

@Component({
  selector: 'app-select-provider-modal',
  templateUrl: './select-provider-modal.component.html',
  styleUrls: ['./select-provider-modal.component.scss']
})
export class SelectProviderModalComponent implements OnInit {

  providers: Array<CustomProvider> = [];
  assessmentTypes: Array<AssessmentTypeApiModel> = [];
  selectedIdx?: number;
  selectedIda?: number;
  employeeEntryExamination:boolean;

  @Input() chooseOrderType: boolean;
  @Input() employerId: number;

  constructor(
    private providerService: ProviderService,
    private ref: NbDialogRef<SelectProviderModalComponent>,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit(): void {
    this.providerService.providerGetProviders(this.employerId).subscribe(r => {
      this.providers = r;
      this.providers.push({
        id: undefined,
        name: 'Nezadaný',
      })
      if (this.providers && this.providers.length > 0) {
        this.selectedIdx = 0;
      }
    });
    if(this.chooseOrderType) {
      this.assessmentService.assessmentGetTypes().subscribe(res => {
        this.assessmentTypes = res;
        if (this.assessmentTypes && this.assessmentTypes.length > 0) {
          this.selectedIda = 0;
        }
      });
    }
  }

  cancel() {
    this.ref.close();
  }

  confirm() {
    const customResponse: ProviderResponseDialog = {provider: this.providers[this.selectedIdx ?? 0], assessment: this.assessmentTypes[this.selectedIda ?? 0]};
    const customResponseOnlyProvider : ProviderResponseDialog = {provider:this.providers[this.selectedIdx ?? 0]};
    if(this.chooseOrderType) this.ref.close(customResponse);
    else this.ref.close(customResponseOnlyProvider);
  }
}
