/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeEligibilityApiModel } from './employeeEligibilityApiModel';
import { EmployeeEligibilityWithAvailabilityApiModelAllOf } from './employeeEligibilityWithAvailabilityApiModelAllOf';
import { EmployeeContractDetailsApiModel } from './employeeContractDetailsApiModel';
import { AssessmentResult } from './assessmentResult';


export interface EmployeeEligibilityWithAvailabilityApiModel { 
    assessibleItemId: number;
    assessibleItemName: string;
    factorClassId: number;
    categoryId: number;
    valid?: string;
    itemEligibilityId?: number;
    assessedItemId?: number;
    result?: AssessmentResult;
    issueDate?: string;
    contracts: Array<EmployeeContractDetailsApiModel>;
    enabled: boolean;
}

