import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  Employer,
  EmployerService,
  Occupation,
  OccupationApiModel,
  OccupationCategorizationService,
  OccupationService
} from 'src/api-new';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import { UserDetailService } from 'src/app/services/user-detail.service';
import { AddEditOccupationModalComponent } from '../add-edit-occupation-modal/add-edit-occupation-modal.component';
import { Sort } from "../../../../../utils";
import { GridfilterService } from 'src/app/services/gridfilter.service';

@Component({
  selector: 'app-occupations-data-grid',
  templateUrl: './occupations-data-grid.component.html',
  styleUrls: ['./occupations-data-grid.component.scss']
})
export class OccupationsDataGridComponent implements OnInit {

  gridFilter$ = new BehaviorSubject(null);
  gridFilter: any;
  gridFilterItems = {
    name: {type: 'name', visible: true, nameType: 'occupation'},
  };
  sorts: Sort[] = [{prop: 'name', dir: 'asc'}];

  onlyActiveOccupations: boolean = true;

  loading$: Observable<boolean>;
  occupations$: Observable<any>;
  rowClass$: Observable<'not-clickable' | 'clickable'>;

  userRole = this.userDetailService.role;
  categorizationId: number;
  currentEmployer: Employer;
  currentEmployerId: number;
  expanded?: Occupation;

  refreshData = new BehaviorSubject(null);

  @ViewChild('myTable') table: any;

  constructor(
    private userDetailService: UserDetailService,
    private avctivatedRoute: ActivatedRoute,
    private categorizationService: OccupationCategorizationService,
    private tableLoader: TableLoaderService,
    private employerService: EmployerService,
    private occupationService: OccupationService,
    private nbDialog: NbDialogService,
    private nbToastr: NbToastrService,
    private gridFilterService: GridfilterService
  ) { }

  ngOnInit(): void {
    this.loading$ = this.tableLoader.loading$;
    this.tableLoader.set(true);
    this.occupations$ = this.refreshData.pipe(
      switchMap(() => {
        if(this.userDetailService.role === 'pzs_manager') {
          return this.avctivatedRoute.queryParams.pipe(
            tap((params) => this.categorizationId = params.categorizationId),
            switchMap((params) => {
              return this.categorizationService.occupationCategorizationGet(params.categorizationId).pipe(
                tap((categorization) => this.currentEmployerId = categorization.employerId),
                switchMap((categorization) => {
                  return this.gridFilter$.pipe(
                    tap(() => this.tableLoader.set(true)),
                    tap((filter) => this.gridFilterService.setFilter(filter)),
                    switchMap(() => {
                      return this.occupationService.occupationGetAll(categorization.employerId).pipe(
                        tap(() => this.tableLoader.set(false))
                      );
                    })
                  );
                })
              );
            })
          )
        } else {
          return this.employerService.employerGetCurrentEmployer().pipe(
            tap((currentEmployer) => this.currentEmployerId = currentEmployer.id),
            switchMap(() => {
              return this.gridFilter$.pipe(
                tap(() => this.tableLoader.set(true)),
                tap((filter) => this.gridFilterService.setFilter(filter)),
                switchMap(() => {
                  return this.occupationService.occupationGetAll(this.currentEmployerId).pipe(
                    tap(() => this.tableLoader.set(false))
                  )
                })
              );
            })
          );
        }
      }),
      catchError((err) => {
        tap(() => this.tableLoader.set(false));
        this.nbToastr.danger('Chyba v komunikácii so serverom', err.error.status, {duration: 5000})
        return []
      })
    );
  }

  updateGridFilter(filter) {
    filter['onlyActiveOccupations'] = this.getAllOccupationsFilterValue();
    this.gridFilter$.next(filter)
  }

  addOccupation() {
    this.nbDialog.open(AddEditOccupationModalComponent, {context: {
      currentEmployerId: this.currentEmployerId
    }} ).onClose.subscribe(
      res => {
        if (res) {
          this.refreshData.next(null);
        }
      }
    );
  }

  editOccupation(occupation: OccupationApiModel) {
    this.nbDialog.open(AddEditOccupationModalComponent, {context: {
      currentEmployerId: this.currentEmployerId,
      occupation: occupation
    }}).onClose.subscribe((res) => {
      if (res) {
        this.refreshData.next(null);
      }
    });
  }

  rowClick(event) {
    // do not open detail, if a checkbox or an input was clicked
    if (event.type === 'click'
      &&
      event.event.target.nodeName === 'DIV' ||
      event.event.target.nodeName === 'SPAN' ||
      event.event.target.nodeName === 'DATATABLE-BODY-CELL'
      ) {
      if (this.expanded === event.row) {
        this.expanded = undefined;
      } else {
        if (this.expanded !== undefined) {
          this.table.rowDetail.toggleExpandRow(this.expanded);
        }
        this.expanded = event.row;
      }
      this.table.rowDetail.toggleExpandRow(event.row);
    }
  }

  getRowClass(row: Occupation) {
    if (!row) {
      return 'not-clickable';
    }
    return 'clickable';
  }

  getAllOccupationsFilterValue() {
    return { val: this.onlyActiveOccupations, condition: 'EQUALS' };
  }

  onlyActiveOccupationsChanged(allOccupations: boolean) {
    this.onlyActiveOccupations = allOccupations;
    let filter = this.gridFilterService.getFilter();
    filter['onlyActiveOccupations'] = this.getAllOccupationsFilterValue();
    this.gridFilterService.setFilter(filter);
    this.refreshData.next(null);
  }

}
