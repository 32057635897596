/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Occupation } from './occupation';
import { AssessibleItem } from './assessibleItem';


export interface OccupationAssessibleItemAllOf { 
    assessibleItemId: number;
    occupationId: number;
    categorizationId: number;
    occupationName?: string;
    assessibleItemName?: string;
    occupation?: Occupation;
    assessibleItem?: AssessibleItem;
}

