import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionPipe } from './pipes/function.pipe';
import { LoadOverlayComponent } from './components/load-overlay/load-overlay.component';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule, NbCheckboxModule, NbDatepickerModule, NbTimepickerModule, NbIconModule,
  NbInputModule, NbSelectModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbUserModule,
  NbFormFieldModule, NbAutocompleteModule,
} from '@nebular/theme';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DialogWrapperComponent } from './components/dialog-wrapper/dialog-wrapper.component';
import { QuickFormComponent } from './components/quick-form/quick-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectProviderModalComponent } from './components/select-provider-modal/select-provider-modal.component';
import { ReadonlyAssessmentComponent } from './components/readonly-assessment/readonly-assessment.component';
import { Mins2timePipe } from './pipes/mins2time.pipe';
import { Mins2numPipe } from './pipes/mins2num.pipe';
import { HoursMinsPipe } from './pipes/hoursmins.pipe';
import { Time2minsPipe } from './pipes/time2mins.pipe';
import { CompanyIdPipe } from './pipes/companyid.pipe'
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { OccupationsDataGridComponent } from './components/occupations/occupations-data-grid/occupations-data-grid.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddEditOccupationModalComponent } from './components/occupations/add-edit-occupation-modal/add-edit-occupation-modal.component';
import { OccupationDetailComponent } from './components/occupations/occupation-detail/occupation-detail.component';
import { AddOccupationAssessibleModalComponent } from './components/occupations/add-occupation-assessible-modal/add-occupation-assessible-modal.component';
import { EmployerFilterComponent } from './components/employer-filter/employer-filter.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { ProviderFilterComponent } from './components/provider-filter/provider-filter.component';
import { AddEditEmployerDialogComponent } from './components/add-edit-employer-dialog/add-edit-employer-dialog.component';
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    FunctionPipe,
    LoadOverlayComponent,
    TruncatePipe,
    ConfirmDialogComponent,
    PageHeaderComponent,
    DialogWrapperComponent,
    QuickFormComponent,
    SelectProviderModalComponent,
    ReadonlyAssessmentComponent,
    Mins2timePipe,
    Mins2numPipe,
    HoursMinsPipe,
    Time2minsPipe,
    CompanyIdPipe,
    GridFilterComponent,
    CalendarComponent,
    OccupationsDataGridComponent,
    AddEditOccupationModalComponent,
    OccupationDetailComponent,
    AddOccupationAssessibleModalComponent,
    EmployerFilterComponent,
    EditAddressComponent,
    ProviderFilterComponent,
    AddEditEmployerDialogComponent,
    ResetPasswordDialogComponent,
  ],
  imports: [
    CommonModule,
    NbSpinnerModule,
    NbCardModule,
    NbButtonModule,
    NbUserModule,
    ReactiveFormsModule,
    NbTooltipModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule,
    NbTimepickerModule,
    NbIconModule,
    NbAccordionModule,
    NbCheckboxModule,
    NbFormFieldModule,
    FormsModule,
    NgxDatatableModule,
    NbAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
  ],
    exports: [
        FunctionPipe,
        LoadOverlayComponent,
        TruncatePipe,
        PageHeaderComponent,
        DialogWrapperComponent,
        QuickFormComponent,
        ReadonlyAssessmentComponent,
        Mins2timePipe,
        Mins2numPipe,
        HoursMinsPipe,
        Time2minsPipe,
        CompanyIdPipe,
        GridFilterComponent,
        CalendarComponent,
        OccupationsDataGridComponent,
        EmployerFilterComponent,
        NbFormFieldModule,
        EditAddressComponent,
        ProviderFilterComponent,
    ]
})
export class SharedModule { }
