<app-dialog-wrapper [actions]="actions">
    <h3 class="col-12">{{editMode ? 'Upraviť detaily' : 'Detail'}} poskytovateľa</h3>
    <form [formGroup]="fg" autocomplete="off">
        <div class="section">Základné údaje:</div>
        <div class="row">
            <div class="col-3">
                <label>IČO *</label>
                <div class="input-with-icon" (click)="onIcoClick()">
                    <input nbInput name="CompanyIco" [placeholder]="'Identifikačné číslo'"
                        [nbTooltip]="'Identifikačné číslo organizácie'" formControlName="companyIco"
                        [ngClass]="{hidden: hiddenControlSubject | async}">
                    <button nbButton status="warning" appearance="ghost" class="icon-button">
                        <nb-icon icon="edit-2-outline"></nb-icon>
                    </button>
                </div>
            </div>
            <div class="col-6">
                <label>Meno poskytovateľa *</label>
                <input nbInput name="companyName" formControlName="companyName"
                    [ngClass]="{hidden: hiddenControlSubject | async}" required>
            </div>

            <div class="col-8">
                <label>Adresa sídla poskytovateľa *</label>
                <app-edit-address [oldInputDesign]="true" [addressData]="provider?.addressDto" [isEditable]="editMode"
                    (outputAddressData)="onAddressDataChanged($event)"
                    [ngClass]="{hidden: hiddenControlSubject | async}"></app-edit-address>
            </div>
            <ng-container *ngIf="!editMode">
                <div class="col-2">
                    <label>Aktívny od *</label>
                    <input nbInput name="effectiveSince" formControlName="effectiveSince"
                        [ngClass]="{hidden: hiddenControlSubject | async}" [nbDatepicker]="datepicker1" required>
                    <nb-datepicker #datepicker1></nb-datepicker>
                </div>
            </ng-container>
        </div>

        <ng-container *ngIf="!editMode">
            <div class="section">Prihlasovacie údaje:</div>
            <div class="row">
                <div class="col-3">
                    <label>Meno používateľa *</label>
                    <input nbInput name="username" formControlName="username"
                        [ngClass]="{hidden: hiddenControlSubject | async}" required>
                </div>
                <div class="col-5">
                    <label>Email *</label>
                    <input nbInput name="email" formControlName="email"
                        [ngClass]="{hidden: hiddenControlSubject | async}" required>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label>Heslo *</label>
                    <input nbInput name="password" formControlName="password" autocomplete="off" type="password"
                        [ngClass]="{hidden: hiddenControlSubject | async}" required>
                </div>
                <div class="col-3">
                    <label>Zopakujte heslo *</label>
                    <input nbInput name="repeatPassword" formControlName="repeatPassword" autocomplete="off"
                        type="password" [ngClass]="{hidden: hiddenControlSubject | async}" required>
                </div>
            </div>
        </ng-container>
    </form>
</app-dialog-wrapper>
