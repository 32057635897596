import { Inject, Injectable } from '@angular/core';
import { AuthService, UserRole } from './auth.service';
import { BASE_PATH, UserService } from '../../api-new';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {

  userName?: string;
  tenantName?: string;
  profilePicture?: string;
  role?: UserRole;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private httpClient: HttpClient,
    @Inject(BASE_PATH) private basePath: string
  ) {
    authService.loggedIn.subscribe(
      (status) => {
        if (status) {
//          const payload = authService.jwt ? JSON.parse(decodeURIComponent(escape(atob(authService.jwt?.split('.')[1])))) : undefined;
          const payload = authService.jwt ? JSON.parse(decodeURIComponent(escape(atob(authService.jwt?.split('.')
            [1].replace(/-/g, '+').replace(/_/g, '/'))))) : undefined;
          if (payload) {
            this.userName = payload.unique_name;
            this.tenantName = payload.tenantName;
            this.role = payload.role;
          }
          this.syncUserPhoto();
        } else {
          this.userName = undefined;
          this.profilePicture = undefined;
          this.role = undefined;
        }
      }
    );
  }

  syncUserPhoto() {
    let imgurl = 'https://ui-avatars.com/api/?background=cccccc&rounded=true&name=' + this.userName?.replaceAll(' ', '+');
    this.userService.userGetUserPhoto().subscribe(
      res => this.profilePicture = res.image && res.mimeType ? `data:${res.mimeType};base64,${res.image}` : imgurl,
      () => this.profilePicture = imgurl
    );
  }
}
