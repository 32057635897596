/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressApiModel } from './addressApiModel';


export interface EmployerApiModel { 
    id: number;
    name: string;
    inlineAddress: string;
    ico: string;
    guid: string;
    address: AddressApiModel;
    isActive: boolean;
}

