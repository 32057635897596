import { Component, Input, OnInit } from '@angular/core';
import {OrderDetailsApiModel} from "../../../../../api-new";
import { AssessmentMiddleService } from "../../../../services/assessment-middle.service";
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';
import {getExaminationTypeName} from "../../../../utils";

@Component({
  selector: 'app-readonly-assessment',
  templateUrl: './readonly-assessment.component.html',
  styleUrls: ['./readonly-assessment.component.scss']
})
export class ReadonlyAssessmentComponent {

  @Input() order: OrderDetailsApiModel;

  constructor(
    private assessmentService: AssessmentMiddleService,
    private location: Location
  ) { }

  printAssessment() {
    this.assessmentService.printAssessment(this.order.id).subscribe(
      blob => {
        switch(blob.type) {
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            saveAs(blob, `posudok-${this.order.id}.docx`);
            break;
          case "application/pdf":
            saveAs(blob, `posudok-${this.order.id}.pdf`);
            break;
          default: break;
        }
      }
    )
  }

  back() {
    this.location.back();
  }

  protected readonly getExaminationTypeName = getExaminationTypeName;
}
