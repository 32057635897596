
<ng-container class="occupation-detail-row">
  <app-load-overlay *ngIf="loading$ | async" class="item-load-overlay"></app-load-overlay>

  <div *ngIf="occupationAssessibleItems$ | async as oais" class="items">
    <div class="list-title" *ngIf="showTitle('RiskFactor')">Rizikové faktory:</div>
    <div *ngFor="let oai of oais">
      <div class="item" *ngIf="oai.assessibleItem.dtype === 'RiskFactor'">
        <span>{{oai.assessibleItemName}}</span>
        <button *ngIf="userRole === 'pzs_manager'"
          nbButton
          style="text-transform: capitalize"
          size="tiny"
          appearance="ghost"
          (click)="removeItem(oai.id)"
          status="danger"
        >
          <nb-icon icon="minus-outline"></nb-icon> Odobrať
        </button>
      </div>
    </div>

    <div class="list-title" *ngIf="showTitle('WorkActivity')">Vybrané činnosti:</div>
    <div *ngFor="let oai of oais">
      <div class="item" *ngIf="oai.assessibleItem.dtype === 'WorkActivity'">
        <span>{{oai.assessibleItemName}}</span>
        <button *ngIf="userRole === 'pzs_manager'"
          nbButton
          style="text-transform: capitalize"
          size="tiny"
          appearance="ghost"
          (click)="removeItem(oai.id)"
          status="danger"
        >
          <nb-icon icon="minus-outline"></nb-icon> Odobrať
        </button>
      </div>
    </div>
  </div>
  
  <button
    *ngIf="userRole === 'pzs_manager'"
    nbButton
    size="small"
    style="margin: 16px"
    (click)="addItem()"
    [disabled]="loading$ | async"
  >
    <nb-icon icon="plus-outline"></nb-icon>
    Pridať faktor/činnosť
  </button>

</ng-container>
