/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssessedItemApiModel { 
    employeeAssessmentId: number;
    assessibleItemId: number;
    assessibleItemGuid: string;
    assessibleItemCode: string;
    id: number;
    name: string;
    maxPeriod: number;
    limitPeriod?: number;
    result: string;
    type: string;
    note?: string;
    categoryId?: number;
    factorClassId?: number;
    factorClassGuid?: string;
    workActivityClassGuid?: string;
    validTo?: string;
}

