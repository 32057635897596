import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";

import { EmployeeService } from "src/api-new/api/employee.service";
import {ExpiringAssessiblesItemsApiModel} from "../../api-new";
import { s2dir } from "../utils";
import {
  EligibilityRow
} from "../modules/competencies/components/competencies-data-grid/competencies-data-grid.component";

@Injectable({
  providedIn: 'root'
})
export class EmployeeMiddleService {

  constructor(
    private employeeService: EmployeeService
  ) { }


  employeesGridFilter: any;
  expiringEligibilitiesGridFilter: any;


  getExpiringEligibilities(page: number,
                           onPage: number,
                           pending: boolean,
                           employerId: number,
                           splitDates: { date: Date }[],
                           loadUntilMonth: Date,
                           showExpired: boolean
  ) {
    const today = new Date();
    return this.employeeService.employeeGetExpiringEligibilitiesNew(employerId, page, onPage,showExpired, pending,  loadUntilMonth.toISOString()).pipe(
      map(eligibilities => ({
        ...eligibilities,
        items: eligibilities.items?.map(eligibility => ({
          id: eligibility.employeeId,
          employerId: employerId,
          employee: eligibility.surname + ' ' + eligibility.name,
          allExpanded: false,
          months: [
            (() => {
              const overdueItems = eligibility.expiringAssessibles?.filter(item => !item.validTo || new Date(item.validTo) < today) ?? [];
              return overdueItems.length > 0 ? overdueItems.map(this.processAssessedItemNew) : null;
            })(),
            ...splitDates.map((_,index) => index).map(i => {
              const monthItems = eligibility.expiringAssessibles?.filter(item => this.getIntervalIndex(splitDates, item.validTo ? new Date(item.validTo) : undefined) === i) ?? [];
              return monthItems.length > 0 ? monthItems.map(this.processAssessedItemNew) : null;
            })],
        } as EligibilityRow))
      }))
    );
  }

  getIntervalIndex(splitDates: {date: Date}[], validTo?: Date) {
    const today = new Date();
    for (let i = 0; i < splitDates.length - 1; ++i) {
      if (!!validTo && validTo >= today && validTo >= splitDates[i].date && validTo < splitDates[i + 1].date) {
        return i;
      }
    }
    return undefined;
  }

  private processAssessedItemNew = (item: ExpiringAssessiblesItemsApiModel) => ({
    type: item.itemType === 'WorkActivity' ? 'Vybrané činnosti' : 'Rizikový faktor',
    name: item.name,
    endDate: item.validTo ? new Date(item.validTo) : undefined,
    urgent: true,
    expired: item.validTo ? new Date(item.validTo) < new Date() : true,
    pending: item.pending,
    assessibleItemId: item.assessibleItemId,
  })
}
