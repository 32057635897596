/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExaminationApiModel } from './examinationApiModel';
import { AssessedItemApiModel } from './assessedItemApiModel';


export interface AssessmentFormApiModel { 
    assessmentId: number;
    assessmentStatus: string;
    assessmentType: string;
    issueDate?: string;
    plannedDate?: string;
    number: string;
    employeeId: number;
    employeeName: string;
    employeeSurname: string;
    employeeDateOfBirth: string;
    employeeAge: number;
    employeeAddress: string;
    employeeProfession: string;
    employeePersonalNumber?: string;
    employeeWorkplace: string;
    employerName: string;
    employerIco: string;
    employerAddress: string;
    referenceEmployeeContractId?: number;
    assessedRiskFactors: Array<AssessedItemApiModel>;
    assessedWorkActivity: Array<AssessedItemApiModel>;
    examinations: Array<ExaminationApiModel>;
}

