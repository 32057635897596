import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import {
  Employer,
  Occupation,
  OccupationApiModel,
  OccupationCreateApiModel,
  OccupationService,
  OccupationUpdateApiModel
} from 'src/api-new';
import { TableLoaderService } from 'src/app/services/table-loader.service';
import { QuickForm } from '../../quick-form/quick-form.component';

@Component({
  selector: 'app-add-edit-occupation-modal',
  templateUrl: './add-edit-occupation-modal.component.html',
  styleUrls: ['./add-edit-occupation-modal.component.scss']
})
export class AddEditOccupationModalComponent implements OnInit {

  currentEmployerId: number;
  occupation: OccupationApiModel;

  actions = [
    {
      label: 'Zrušiť',
      status: "basic",
      click: () => this.ref.close(false)
    },
    {
      label: 'Potvrdiť',
      status: 'primary',
      click: () => this.save(),
      disabled: () => this.fg.invalid
    }
  ];

  fg: UntypedFormGroup;
  formDef: QuickForm;
  editMode = false;
  loadingOccupation = false;

  constructor(
    private tableLoader: TableLoaderService,
    private ref: NbDialogRef<AddEditOccupationModalComponent>,
    private occupationService: OccupationService,
    private nbToastr: NbToastrService,
  ) { }

  ngOnInit(): void {
    let formDef: QuickForm = {
      controls: [
        {
          formControlName: 'name',
          placeholder: 'Názov profesie',
          type: 'text',
        },
        {
          formControlName: 'valid',
          placeholder: 'Platný',
          type: 'check',
        },
        {
          formControlName: 'completePeriodicCheck',
          placeholder: 'Kompletná periodická prehliadka',
          type: 'check',
        },
      ]
    };
    if (this.occupation) this.editMode = true;
    this.formDef = formDef;
  }

  formInit(fg: UntypedFormGroup) {
    this.fg = fg;
    this.fg.patchValue({
      valid: false, // when unchecked, after init, returns null
      completePeriodicCheck: false,
    });
    if (this.editMode) {
      this.fg.patchValue({
        name: this.occupation.name,
        valid: this.occupation.valid,
        completePeriodicCheck: this.occupation.allRiskFactorExam
      })
    }
  }

  save() {
    this.tableLoader.set(true);
    if (this.editMode) {
      const updateDto: OccupationUpdateApiModel = {
        id: this.occupation.id,
        name: this.fg.value.name,
        valid: this.fg.value.valid,
        allRiskFactorExam: this.fg.value.completePeriodicCheck
      };
      this.occupationService.occupationUpdate(updateDto).subscribe(
        () => this.ref.close(true),
        (error) => {
          this.tableLoader.set(false);
          this.nbToastr.danger(error.error.error ?? 'Nastala chyba v komunikácii so serverom.', 'Chyba', {duration: 5000});
        }
      )
    } else {
      const createDto: OccupationCreateApiModel = {
        employerId: this.currentEmployerId,
        name: this.fg.value.name,
        valid: this.fg.value.valid,
        allRiskFactorExam: this.fg.value.completePeriodicCheck
      };
      this.occupationService.occupationCreate(createDto).subscribe(
      () => {
        this.ref.close(true);
      },
      (err) => {
        this.tableLoader.set(false);
          this.nbToastr.danger('Chyba v komunikácii so serverom', err.status, {duration: 5000});
        }
      );
    }
  }

}
