/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeEligibilityWithAvailabilityApiModel } from './employeeEligibilityWithAvailabilityApiModel';
import { EmployeeEligibilityApiModel } from './employeeEligibilityApiModel';


export interface EmployeeAssessibilitiesApiModel { 
    riskFactors: Array<EmployeeEligibilityApiModel>;
    workActivities: Array<EmployeeEligibilityWithAvailabilityApiModel>;
    explicitWorkActivities: Array<EmployeeEligibilityApiModel>;
    explicitRiskFactors: Array<EmployeeEligibilityApiModel>;
}

