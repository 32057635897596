/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessibleItem } from './assessibleItem';
import { OccupationCategorization } from './occupationCategorization';
import { MedicalExaminationType } from './medicalExaminationType';


export interface MedicalExaminationAllOf { 
    code?: string;
    name: string;
    assessibleItemId: number;
    examinationTypeId: number;
    categorizationId: number;
    medicalExaminationType?: MedicalExaminationType;
    assessibleItem?: AssessibleItem;
    categorization?: OccupationCategorization;
}

