import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDetailService } from '../services/user-detail.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard  {

  constructor(private userDetailService: UserDetailService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.userDetailService.role;
    return !(!role || (next.data.roles && !next.data.roles.some(r => r === role)));
  }
}
