import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Employer, EmployerApiModel, EmployerService} from "../../api-new";
import {AuthService, UserRole} from "./auth.service";
import {filter} from "rxjs/operators";
import {UserDetailService} from './user-detail.service';

@Injectable({
  providedIn: 'root'
})
export class EmployerFilterService {
  public selectedEmployer$ = new BehaviorSubject<EmployerApiModel | null>(null);
  public loadedEmployers$ = new BehaviorSubject<Array<EmployerApiModel> | null>(null);
  public _disable = new Subject<boolean>();
  public disable$ = this._disable.asObservable();

  constructor(
    authService: AuthService,
    private employerService: EmployerService,
    private userDetailService: UserDetailService
  ) {
    authService.loggedIn.pipe().subscribe((loggedIn) => {
      if (loggedIn) {
        this.reload()
      } else {
        this.reset()
      }
    })
  }

  public reload() {
    this.employerService.employerGetAllEmployers().toPromise().then(employers => {
      this.loadedEmployers$.next(employers);
      if (this.userDetailService.role === UserRole.human_resource_manager) {
        this.changeEmployee(employers[0].id);
      }
      if (employers.length == 1) {
        this.changeEmployee(employers[0].id);
      }
    });
  }


  public reset() {
    this.selectedEmployer$.next(null);
    this.loadedEmployers$.next(null);
  }


  public getSelectedEmployer() {
    return this.selectedEmployer$.getValue();
  }


  public getLoadedEmployers() {
    return this.loadedEmployers$.getValue();
  }

  changeEmployee(employerId: number | null) {
    if (employerId) {
      let employer = this.getLoadedEmployers()?.find(employer => employer.id === employerId)
      if (employer != null) {
        this.selectedEmployer$.next(employer);
      }
    } else {
      this.selectedEmployer$.next(null);
    }
  }
}
