/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentInterval } from './assessmentInterval';
import { MedicalExamination } from './medicalExamination';


export interface AssessibleItemAllOf { 
    name: string;
    specification: string;
    code: string;
    dtype: string;
    categoryId?: number;
    factorClassId?: number;
    workActivityClassId?: number;
    categorizationId?: number;
    guid: string;
    intervals: Array<AssessmentInterval>;
    medicalExaminations?: Array<MedicalExamination>;
    contractIds?: Array<number>;
}

