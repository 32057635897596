import {Component, EventEmitter, Input, Output} from '@angular/core';
import { shiftDate } from '../../../../utils';
import {
  CalendarApiModel,
  AppointmentApiModelNew
} from '../../../../../api-new';
import {NbDialogService} from "@nebular/theme";
import {
  UserCalendarDialogComponent
} from "../../../user-calendar/dialog/user-calendar-dialog/user-calendar-dialog.component";
// import * as child_process from "child_process";

export interface CalendarAppointment {
  appointmentStatus:string
  employeeName: string,
  employeeSurname: string,
  plannedDate: string,
  requireSample: boolean
}

export interface Day {
  date: Date;
  appointments: CalendarAppointment[];
}

export interface Week {
  days: Day[];
}

enum UserCalendarEnum {
  PROVIDER,
  EMPLOYER
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @Output() monthChange: EventEmitter<number>
  today = new Date(new Date().setHours(0, 0, 0, 0));
  date = new Date();
  calendarMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 15);

  startDate = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1));

  endDate = this.getEndDate();

  dayNames = ['Pon', 'Uto', 'Str', 'Štv', 'Pia'];
  monthWeeks: Week[];

  userCalendarType: UserCalendarEnum;

  calendarAppointments: Array<CalendarAppointment> = [];
  calendar: CalendarApiModel;

  constructor(private dialogService: NbDialogService) {
    this.monthChange = new EventEmitter<number>();
  }

  @Input() set setAppointments(value: Array<AppointmentApiModelNew>) {
    this.calendarAppointments = value?.map(appointment => {
      appointment.plannedDate = new Date(appointment.plannedDate).toUTCString();
      return appointment
    });
    this.monthWeeks = this.computeWeeks();
    this.userCalendarType = UserCalendarEnum.EMPLOYER;
  }

  getEndDate() {
    return shiftDate(this.startDate, 5);
  }

  shiftCalendarMonth(shift?: number) {
    if(shift) {
      this.monthChange.emit(shift);
      this.calendarMonth = new Date(this.calendarMonth.getFullYear(), this.calendarMonth.getMonth() + shift, 15);
      this.monthWeeks = this.computeWeeks();
    }
  }

  computeWeeks() {
    const weeks: Week[] = [];
    const numberOfDaysInWeek = 5;
    const days = this.completeMonthWeeks()
    .filter(day => {
      if (day.getUTCDay() !== 5 && day.getUTCDay() !== 6) {
        return day;
      }
    })
    .map(day => {
      return {
        date: day,
        appointments: this.calendarAppointments?.filter(appointment => {
          return new Date(appointment.plannedDate).toDateString() === day.toDateString();
        })
      }
    })
    for(let i = 0; i < days.length; i += numberOfDaysInWeek) {
      const week: Week = {
        days: days.slice(i, i + numberOfDaysInWeek)
      }
      weeks.push(week);
    }
    return weeks;
  }

  completeMonthWeeks() {
    let fullWeekMonthDates = this.getMonthDates();
    const firstMonthDate = fullWeekMonthDates[0];
    const lastMonthDate = fullWeekMonthDates[fullWeekMonthDates.length - 1];
    if(firstMonthDate.getUTCDay() !== 0) { // if not monday -> add days backward until monday
      fullWeekMonthDates = fullWeekMonthDates.reverse();
      for(let i = 1; i <= firstMonthDate.getUTCDay(); i++) {
          fullWeekMonthDates.push(new Date(
          firstMonthDate.getFullYear(),
          firstMonthDate.getMonth(),
          firstMonthDate.getDate() - i, 0));
      }
      fullWeekMonthDates = fullWeekMonthDates.reverse();
    }
    if(lastMonthDate.getUTCDay() !== 7) { // if not sunday -> add days forward until sunday
      for(let i = 1; i <= 6 - lastMonthDate.getUTCDay(); i++) {
        fullWeekMonthDates.push(new Date(
          lastMonthDate.getFullYear(),
          lastMonthDate.getMonth(),
          lastMonthDate.getDate() + i, 0));
      }
    }
    return fullWeekMonthDates;
  }

  getMonthDates() {
    const year = this.calendarMonth.getFullYear();
    const month = this.calendarMonth.getMonth();
    const date = new Date(year, month, 1);
    let days: Array<Date> = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  openDetails(appointmentStatus: any) {
    const dialogRef = this.dialogService.open(UserCalendarDialogComponent,{
      context: {
        dialogAppointmentStatus: appointmentStatus.appointmentStatus,
        dialogEmployeeAssessmentId: appointmentStatus.employeeAssessmentId,
        dialogProvider: appointmentStatus.providerName,
        dialogEmployeeId: appointmentStatus.employeeId
      }
    });
  }

}
