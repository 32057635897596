/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeApiModel } from './employeeApiModel';
import { AssessedWorkActivityApiModel } from './assessedWorkActivityApiModel';
import { AssessmentStatus } from './assessmentStatus';
import { EmployerApiModel } from './employerApiModel';
import { OccupationApiModel } from './occupationApiModel';
import { AssessmentExaminationApiModel } from './assessmentExaminationApiModel';
import { WorkplaceApiModel } from './workplaceApiModel';
import { AssessedRiskFactorApiModel } from './assessedRiskFactorApiModel';
import { AssessmentTypeEnum } from './assessmentTypeEnum';


export interface OrderDetailsApiModel { 
    id: number;
    status: AssessmentStatus;
    number: string;
    type: AssessmentTypeEnum;
    createDate: string;
    orderDate?: string;
    plannedDate?: string;
    employer: EmployerApiModel;
    employee: EmployeeApiModel;
    occupation: OccupationApiModel;
    workplace: WorkplaceApiModel;
    assessedRiskFactors: Array<AssessedRiskFactorApiModel>;
    assessedWorkActivities: Array<AssessedWorkActivityApiModel>;
    examinations: Array<AssessmentExaminationApiModel>;
}

