<div class="actions-right">
  <button nbButton
          status="primary"
          size="small"
          (click)="addOccupation()"
          [disabled]="loading$ | async">
            <nb-icon icon="plus-outline"></nb-icon>
            Pridať profesiu
  </button>
</div>

<app-grid-filter
  [gridFilterItems]="gridFilterItems"
  (gridFilterEmitter)="updateGridFilter($event)">

  <div class="currently-valid">
    <nb-checkbox status="basic" class="label" [checked]="onlyActiveOccupations"
      (checkedChange)="onlyActiveOccupationsChanged($event)">Platné profesie</nb-checkbox>
  </div>

</app-grid-filter>

<ngx-datatable
  #myTable
  class="fix-scroller-width"
  [columnMode]="'flex'"
  [headerHeight]="35"
  [footerHeight]="35"
  [rowHeight]="35"
  [rows]="occupations$ | async"
  [messages]="{emptyMessage: 'Žiadne dáta na zobrazenie', totalMessage: 'spolu', selectedMessage: 'vybraných'}"
  [rowClass]="getRowClass"
  (activate)="rowClick($event)"
  [loadingIndicator]="loading$ | async"
  [limit]="10"
  [sorts]="sorts"
>

  <ngx-datatable-column name="Profesia" prop="name" [flexGrow]="12">
    <ng-template ngx-datatable-cell-template let-row="row">
      <span [ngClass]="{highlight: row === expanded}">{{row.name}}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Kompletná periodická prehliadka" prop="allRiskFactorExam" [flexGrow]="6">
    <ng-template ngx-datatable-cell-template let-row="row">
      <nb-icon *ngIf="row.allRiskFactorExam" icon="checkmark-outline" status="success"></nb-icon>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Platná" prop="valid" [flexGrow]="2" [resizeable]="false">
    <ng-template ngx-datatable-cell-template let-row="row">
      <nb-icon *ngIf="row.valid" icon="checkmark-outline" status="success"></nb-icon>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Akcie" prop="id" [flexGrow]="2" [resizeable]="false">
    <ng-template ngx-datatable-header-template>
      <div class="table-align-start">Akcie </div>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <div class="table-align-start">
        <button nbButton
                status="primary"
                size="small"
                appearance="ghost"
                (click)="editOccupation(row)">
                  <nb-icon icon="edit-outline"></nb-icon>
        </button>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-row-detail [rowHeight]="'auto'">
    <ng-template let-row="row" ngx-datatable-row-detail-template>
      <app-occupation-detail
        [employerId]="currentEmployerId"
        [occupationId]="row.id"
        [occupationName]="row.name"
      ></app-occupation-detail>
    </ng-template>
  </ngx-datatable-row-detail>


</ngx-datatable>
