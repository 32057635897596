/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessibleItem } from './assessibleItem';
import { OccupationAssessibleItem } from './occupationAssessibleItem';
import { MedicalExamination } from './medicalExamination';


export interface OccupationCategorizationAllOf { 
    number: string;
    employerId: number;
    categorizationProviderId: number;
    issueDate?: string;
    validSince?: string;
    validUntil?: string;
    occupationAssessibleItems?: Array<OccupationAssessibleItem>;
    assessibleItems?: Array<AssessibleItem>;
    medicalExaminations?: Array<MedicalExamination>;
}

